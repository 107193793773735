@use "breakpoints";
@use "buttons";
@use "colors";
@use "typography";

.error-page {
  background-color: colors.$black;
}

.header {
  background-color: colors.$white;
}

.logo-wrap {
  align-items: center;
  block-size: 10vh;
  display: flex;
  margin: auto;
  max-inline-size: 1192px;
  padding-inline-start: 2.4rem;
}

.logo {
  display: block;
  inline-size: 200px;
}

.content {
  color: colors.$white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-inline: auto;
  max-inline-size: 1192px;
  min-block-size: 80vh;
  padding-inline: 2.4rem;
}

.title {
  @include typography.xl-headline;
}

.description {
  margin-block-start: 2rem;
}

.return-home-button {
  @include buttons.button(
    buttons.$theme-secondary-light,
    buttons.$width-secondary
  );

  margin-block-start: 10rem;
}

@media (min-width: breakpoints.$lg) {
  .title,
  .description {
    inline-size: 50%;
  }
}
